import styled from 'styled-components'
import { theme, media } from '../../helpers/theme'

const HomeHero = styled.div`
  position: relative;
  overflow: hidden;

  margin-bottom: ${theme.space[8]}px;

  color: ${theme.colors.font.white};

  ${media('md')} {
    height: 535px;
  }

  ${media('lg')} {
    height: 570px;
  }

  ${media('2200')} {
    height: unset;
  }
`

const Logo = styled.img`
  height: auto;
  max-width: 475px;
  width: 100%;
  margin-bottom: ${theme.space[6]}px;
`

const MobileWaveImg = styled.img`
  display: block;
  grid-column-start: 1;
  grid-row-start: 1;
  left: 0;
  max-width: 1010px;
  position: absolute;
  top: -1px;
  transition: all 0.5s;
  z-index: -1;

  ${media('lg')} {
    display: none;
  }

  ${media('2200')} {
    position: static;
    z-index: unset;
    max-width: unset;
    width: 100%;
  }
`

const DesktopWaveImg = styled.img`
  display: none;
  grid-column-start: 1;
  grid-row-start: 1;
  left: 0;
  max-width: 1010px;
  position: absolute;
  top: -1px;
  z-index: -1;

  transition: all 0.5s;

  ${media('lg')} {
    display: block;
    max-width: 1885px;
  }

  ${media('xl')} {
    max-width: 2215px;
  }

  ${media('2200')} {
    position: static;
    max-width: unset;
    width: 100%;
  }
`

const Content = styled.div`
  width: 100%;
  grid-row-start: 1;
  grid-column-start: 1;

  max-width: ${theme.breakpoints['xl']}px;
  margin: 0 auto;

  padding: ${theme.space[4]}px;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 76px;

  ${media('md')} {
    flex-direction: row;
    padding: ${theme.space[8]}px;
    padding-bottom: 95px;
  }

  ${media('lg')} {
    padding: 0;
  }
  ${media('2200')} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

const ContentItem = styled.div`
  flex: 1;
  flex-basis: 40%;

  display: flex;
  flex-direction: column;

  margin: 0 ${theme.space[8]}px;
`

const ImageItem = styled.div`
  flex: 1;
  flex-basis: 50%;
  margin: 0 ${theme.space[8]}px;

  display: flex;
  justify-content: center;
  flex-direction: column;

  ${media('sm')} {
    display: block;
    margin: 0 auto;
  }
`

const Image = styled.img`
  max-width: 100%;
  margin-bottom: ${theme.space[8]}px;

  ${media('sm')} {
    margin-bottom: 0;
  }
`

const Title = styled.span`
  font-weight: bold;
  text-align: center;
  font-size: ${theme.font['2xl']};
  ${media('md')} {
    font-size: ${theme.font['3xl']};
    text-align: initial;
  }
  ${media('lg')} {
    font-size: ${theme.font['4xl']};
  }
`

const Subtitle = styled.span`
  font-weight: bold;
  margin-bottom: ${theme.space[6]}px;
  text-align: center;
  font-size: ${theme.font['md']};

  ${media('md')} {
    font-size: ${theme.font['lg']};
    text-align: initial;
  }
  ${media('xl')} {
    font-size: ${theme.font['xl']};
  }
`

const Text = styled.span`
  margin-bottom: ${theme.space[4]}px;
  text-align: center;
  font-size: ${theme.font['lg']};

  ${media('md')} {
    font-size: ${theme.font['lg']};
    text-align: initial;
  }
  ${media('xl')} {
    font-size: ${theme.font['xl']};
  }
`

const Button = styled.a`
  background-color: ${theme.colors.accent};
  border: none;
  border-radius: 5px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  color: ${theme.colors.font.black};
  padding: ${theme.space[4]}px ${theme.space[8]}px;
  text-decoration: none;
  white-space: nowrap;
  font-weight: bold;

  font-size: ${theme.font['lg']};

  ${media('sm')} {
    font-size: ${theme.font['md']};
  }
`

const DesktopButton = styled.div`
  display: none;
  ${media('sm')} {
    display: flex;
    justify-content: center;
    margin-bottom: ${theme.space[8]}px;
  }
  ${media('md')} {
    justify-content: flex-start;
  }
`

const MobileButton = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  ${media('sm')} {
    display: none;
  }
`

HomeHero.mobileWave = MobileWaveImg
HomeHero.desktopWave = DesktopWaveImg
HomeHero.content = Content
HomeHero.contentItem = ContentItem
HomeHero.imageItem = ImageItem
HomeHero.image = Image
HomeHero.Logo = Logo
HomeHero.title = Title
HomeHero.subtitle = Subtitle
HomeHero.text = Text
HomeHero.button = Button
HomeHero.desktopButton = DesktopButton
HomeHero.mobileButton = MobileButton

export default HomeHero
