import React from 'react'

import SEO from '../components/seo'
import Layout from '../components/layout'
import Footer from '../components/footer'
import WoocommerceHero from '../components/woocommerce-hero'
import WoocommercePartnerSection from '../components/woocommerce-partner-section'
import WoocommerceMaskedContent from '../components/woocommerce-masked-content'
import WoocommerceCTA from '../components/woocommerce-cta'
import SaleModal from '../components/sale-modal'

const Woocommerce = () => {
  return (
    <Layout isWhiteLogo hideLinks>
      <SEO
        title='Email marketing for WooCommerce Stores'
        pageType='home'
        flowType='none'
      />
      <WoocommerceHero />
      <WoocommercePartnerSection />
      <WoocommerceMaskedContent />
      <WoocommerceCTA />
      <Footer />
      <SaleModal />
    </Layout>
  )
}

export default Woocommerce
