import React from 'react'
import Content from './woocommerce-masked-content.styles'
import YellowWave from '../../images/yellow-wave.svg'
import { graphql, useStaticQuery } from 'gatsby'

const MaskedContent = () => {
  const { sync } = useStaticQuery(
    graphql`
      query {
        sync: file(relativePath: { eq: "contactSync@2x.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <Content>
      <Content.Container>
        <Content.Grid>
          <Content.TextContainer>
            <Content.Text>
              <Content.Title>Automatic Form Contacts Sync</Content.Title>
              <Content.Description>
              In addition to WooStore store customers, opted-in contacts can also be added via your Jetpack Newsletter Sign-Up Form. Contacts will then be synced automatically (for WordPress.com Business & Ecommerce plans) into Creative Mail.
               Their source is identified within Creative Mail and are ready for inclusion  in your marketing campaigns.
              </Content.Description>
            </Content.Text>
          </Content.TextContainer>
          <Content.ImageContainer>
            <Content.Image
              fluid={sync.childImageSharp.fluid}
              alt='phone image'
              imgStyle={{ right: '0px', left: 'unset' }}
            />
          </Content.ImageContainer>
        </Content.Grid>
      </Content.Container>
      <Content.Wave src={YellowWave} alt='background wave' />
    </Content>
  )
}

export default MaskedContent
