import React from 'react'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'

import CallToAction from './woocommerce-cta.style'

import homeFooterWave from './../../images/homeFooterWave.svg'
import bullet1 from './../../images/bullet-1.svg'
import bullet2 from './../../images/bullet-2.svg'
import bullet3 from './../../images/bullet-3.svg'
import bullet4 from './../../images/bullet-4.svg'

const CTAComponent = () => {

  return (
  <CallToAction>
    <CallToAction.container>
      <CallToAction.header>
        WooCommerce Integration Details.
      </CallToAction.header>
      <CallToAction.bulletSection>
        <CallToAction.textContainer>
          <CallToAction.features>
            <CallToAction.bullet>
              <CallToAction.listBullet first src={bullet1} />
              <CallToAction.bulletText>
                <CallToAction.bulletText _bold>
                  {`Enhanced Ecommerce: `}
                </CallToAction.bulletText>
                WooCommerce store customers and ecommerce interactions are all captured automatically within your email marketing list.
                 That way, you can retarget and re-engage your customers whenever you want.
              </CallToAction.bulletText>
            </CallToAction.bullet>
            <CallToAction.bullet>
              <CallToAction.listBullet src={bullet2} />
              <CallToAction.bulletText>
                <CallToAction.bulletText _bold>
                  {`Beautiful Transactional Emails: `}
                </CallToAction.bulletText>
                The look of standard WooCommerce emails can be replaced to match your branding and style.
                 Build one, and then all your other WooCommerce emails managed by Creative will inherit the same look.
              </CallToAction.bulletText>
            </CallToAction.bullet>
            <CallToAction.bullet>
              <CallToAction.listBullet src={bullet3} />
              <CallToAction.bulletText>
                <CallToAction.bulletText _bold>
                  {`Jetpack Forms Integration: `}
                </CallToAction.bulletText>
                Collect, sync, and manage opt-in subscribers directly from Jetpack forms into Creative Mail.
              </CallToAction.bulletText>
            </CallToAction.bullet>
            <CallToAction.bullet>
              <CallToAction.listBullet src={bullet4} />
              <CallToAction.bulletText>
                <CallToAction.bulletText _bold>
                  {`Build Better Branding: `}
                </CallToAction.bulletText>
                Creative Mail includes our free LogoMaker and image editing suite to enhance your brand.
              </CallToAction.bulletText>
            </CallToAction.bullet>
          </CallToAction.features>
        </CallToAction.textContainer>
        <CallToAction.textContainer>
          <CallToAction.features>
            <CallToAction.bullet>
              <CallToAction.listBullet first src={bullet3} />
              <CallToAction.bulletText>
                <CallToAction.bulletText _bold>
                  {`Amazing Stock Images: `}
                </CallToAction.bulletText>
                You get free access to the integrated stock photo library &#8212;in addition to your own WordPress media library&#8212;to build marketing
                campaigns with award-winning images.
              </CallToAction.bulletText>
            </CallToAction.bullet>
            <CallToAction.bullet>
              <CallToAction.listBullet src={bullet4} />
              <CallToAction.bulletText>
                <CallToAction.bulletText _bold>
                  {`Get Better Deliverability: `}
                </CallToAction.bulletText>
                Other email marketing solutions require complex SMTP solutions or external gateways, or they make you use their less than stellar IPs. As a result,
                emails sometimes get bounced or are never delivered. Creative Mail is an all-in-one solution that uses Constant Contact's rock-solid infrastructure for 
                superior deliverability.
              </CallToAction.bulletText>
            </CallToAction.bullet>
            <CallToAction.bullet>
              <CallToAction.listBullet src={bullet1} />
              <CallToAction.bulletText>
                <CallToAction.bulletText _bold>
                  {`Live Support: `}
                </CallToAction.bulletText>
                With our paid plans (Awesome and Ultimate), you get access to phone and chat support to help you get answers from real live humans.
                 Imagine That....
              </CallToAction.bulletText>
            </CallToAction.bullet>
          </CallToAction.features>
        </CallToAction.textContainer>
      </CallToAction.bulletSection>
    </CallToAction.container>
    <CallToAction.wave src={homeFooterWave} />
    <CallToAction.buttonContainer>
      <CallToAction.buttonHeader>
        <span>Ready to give it a spin?</span>
      </CallToAction.buttonHeader>
      <CallToAction.text>
        <span>
          Don’t take our word for it, get the plugin to experience Creative Mail
          for yourself.
        </span>
      </CallToAction.text>
      <CallToAction.button
        data-element-location={DataElementLocations.BODY}
        data-element-label='cta.button'
        data-element-id='cta-button-get-started'
        href={process.env.GATSBY_PLUGIN_URL}
      >
        <span>GET STARTED</span>
      </CallToAction.button>
    </CallToAction.buttonContainer>
  </CallToAction>
)}

export default CTAComponent
