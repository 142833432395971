import React from 'react'

import Layout from './woocommerce-partner-section.styles'
import JetpackForm from './../../images/product@2x.png'

const WoocommercePartnerSection = () => {
  return (
    <Layout>
      <Layout.partnership>
        <Layout.title>Get more from your WooCommerce store!</Layout.title>
        <Layout.text>
          Creative Mail is the easiest way to manage and brand all of your important transactional WooCommerce emails, and since it’s powered by Constant Contact, you get some of the highest deliverability in the business.
           Creative Mail also helps you automatically add contacts from Woo Commerce store purchases and website forms, appropriately opt-in subscribers, and build beautiful newsletters and product promotions quickly and easily.
        </Layout.text>
      </Layout.partnership>
      <Layout.grid>
        <Layout.content>
          <Layout.formImg src={JetpackForm} />

          <Layout.formInfo>
            <Layout.title>It's WooProducts Aware!</Layout.title>
            <Layout.text>
              If you've got a WooCommerce store, it's time to "Get Creative" with your email marketing. Creative Mail is automatically aware of the products you sell
              and will feature them as content in your emails.
            </Layout.text>
            <br />
            <Layout.text>
            Creative Mail also lets you offer multi-step campaigns, or “journeys,” that take your audience through a series of emails helping you drive customer engagement and sell more stuff!
            </Layout.text>
          </Layout.formInfo>
        </Layout.content>
      </Layout.grid>
    </Layout>
  )
}

export default WoocommercePartnerSection
