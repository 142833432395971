import React from 'react'

import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'

import WoocommerceHeroLayout from './woocommerce-hero.styles'

import hero from './../../images/woocommerce-hero@2x.png'
import DesktopWave from './../../images/Home-header-shape.svg'
import MobileWave from './../../images/Home-header-shape-mobile.svg'
import woocommerceHeaderImage from './../../images/woocommerce-header-logo.png'

const JetpackHero = () => {
  return (
    <WoocommerceHeroLayout>
      <WoocommerceHeroLayout.mobileWave src={MobileWave} />
      <WoocommerceHeroLayout.desktopWave src={DesktopWave} />
      <WoocommerceHeroLayout.content>
        <WoocommerceHeroLayout.contentItem>
          <WoocommerceHeroLayout.Logo src={woocommerceHeaderImage} alt='Woocommerce Header' />
          <WoocommerceHeroLayout.text>
          Creative Mail was built to enhance WooCommerce selling.
           Easily message, promote and retarget customers right from your WordPress Dashboard.
          </WoocommerceHeroLayout.text>
          <WoocommerceHeroLayout.desktopButton>
            <WoocommerceHeroLayout.button
              href={process.env.GATSBY_WOOCOMMERCE_PLUGIN_URL}
              data-element-location={DataElementLocations.HERO}
              data-element-label='home.hero.ctaDesktop'
              data-element-id='creativemail-home-hero-cta-desktop'
            >
              Get started for FREE
            </WoocommerceHeroLayout.button>
          </WoocommerceHeroLayout.desktopButton>
        </WoocommerceHeroLayout.contentItem>
        <WoocommerceHeroLayout.imageItem>
          <WoocommerceHeroLayout.image src={hero} />
          <WoocommerceHeroLayout.mobileButton>
            <WoocommerceHeroLayout.button
              href={process.env.GATSBY_PLUGIN_URL}
              data-element-location={DataElementLocations.HERO}
              data-element-label='home.hero.ctaMobile'
              data-element-id='creativemail-home-hero-cta-mobile'
            >
              Get started for FREE
            </WoocommerceHeroLayout.button>
          </WoocommerceHeroLayout.mobileButton>
        </WoocommerceHeroLayout.imageItem>
      </WoocommerceHeroLayout.content>
    </WoocommerceHeroLayout>
  )
}

export default JetpackHero
